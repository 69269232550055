import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useStationAuth } from "../../contexts/StationAuthContext";
import Iconify from "../Iconify";
import TermsAndConditions from "../Terms&Conditions";
import SweepstakesRules from "../SweepstakesRules";
import useResponsive from "../../hooks/useResponsive";
import PetView from "./PetView";
import StationDetails from "../home/StationDetails";
import {
  useCanClaimPetQuery,
  useGetUserPetsQuery,
} from "../../generated/graphql";
// ----------------------------------------------------------------------

export default function Sidebar() {
  const { playerId, showPetSidebar, setShowPetSidebar } = useStationAuth();

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showSweepstakesRules, setShowSweepstakesRules] = useState(false);
  const [tutorialModeStep, setTutorialModeStep] = useState(0);

  const isInGame = useLocation().pathname.includes("/game");
  const isMd = useResponsive("up", "md");

  const [{ data: userPetsData }] = useGetUserPetsQuery({
    pause: !playerId,
    variables: { playerId: playerId! },
  });
  const userPets = userPetsData?.getUserPets;

  const [{ data: canClaimPetData }] = useCanClaimPetQuery({
    variables: { playerId: playerId! },
    pause: !playerId,
  });
  const canClaimPet = canClaimPetData?.canClaimPet;
  const showClaimPet = canClaimPet && userPets?.length === 0;

  const SidebarContent = (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {playerId ? (
          <PetView
            tutorialModeStep={tutorialModeStep}
            setTutorialModeStep={setTutorialModeStep}
          />
        ) : (
          <NonLoggedInView />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          my: 2,
        }}
      ></Box>
    </>
  );

  const RightMobileDrawer = (
    <Drawer
      variant="temporary"
      anchor="right"
      open={showPetSidebar}
      onClose={() => setShowPetSidebar(false)}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: "block", lg: "none" },
        "& .MuiDrawer-paper": {
          overflow: "visible",
          boxSizing: "border-box",
          width: isMd ? 300 : "100%",
          maxHeight: isMd ? "auto" : `calc(100vh - 75px)`,
          overflowY: "auto",
          background:
            "linear-gradient(172deg, rgba(86,138,254,1) 34%, rgba(255,55,128,1) 74%)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          p: 2,
        }}
      >
        {SidebarContent}
      </Box>
    </Drawer>
  );

  return (
    <>
      {/* Add right menu button to top bar */}
      {tutorialModeStep > 0 && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor:
              tutorialModeStep === 4
                ? "rgba(0, 0, 0, 0)"
                : "rgba(0, 0, 0, 0.9)",
            zIndex: 98,
            "@media (max-width: 1200px)": {
              display: "none",
            },
            transition: "background-color 3s ease",
          }}
        />
      )}

      {showClaimPet ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            zIndex: 1200,
            "@media (max-width: 1200px)": {
              display: "none",
            },
            transition: "background-color 3s ease",
          }}
        />
      ) : null}

      {/* Desktop Sidebar */}
      {isMd && (
        <Box
          id="sidebar"
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            justifyContent: "space-between",
            background: isInGame
              ? "linear-gradient(to bottom right, rgb(22 28 36), rgb(127 75 55 / 95%))"
              : "linear-gradient(172deg, rgba(86,138,254,1) 34%, rgba(255,55,128,1) 74%)",
            backgroundSize: "cover",
            backgroundBlendMode: "overlay",
            width: "300px",
            height: "100vh",
            maxHeight: "100vh",
            zIndex: showClaimPet ? 1201 : 99,
            position: "relative",
            "@media (max-width: 1200px)": {
              display: "none",
            },
          }}
        >
          {SidebarContent}
        </Box>
      )}

      {/* Mobile Right Drawer */}
      {!isMd && <> {RightMobileDrawer}</>}
      <TermsAndConditions
        showTermsAndConditions={showTermsAndConditions}
        setShowTermsAndConditions={setShowTermsAndConditions}
      />
      <SweepstakesRules
        showSweepstakesRules={showSweepstakesRules}
        setShowSweepstakesRules={setShowSweepstakesRules}
      />
    </>
  );
}

export const NonLoggedInView = () => {
  const { setShowLoginForm } = useStationAuth();
  return (
    <Box
      id="non-logged-in-view"
      onClick={() => {
        if (window.__TAURI__) setShowLoginForm(true);
        else setShowLoginForm(true);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        {window.__TAURI__ ? (
          <img
            src="/images/sidebar/card.gif"
            alt="card"
            width="150px"
            style={{
              cursor: "pointer",
            }}
          />
        ) : (
          <img
            src="/images/sidebar/card.gif"
            alt="card"
            width="150px"
            style={{
              cursor: "pointer",
            }}
          />
        )}
      </Box>

      <Button
        className="slanted-button"
        variant="text"
        onClick={() => {
          setShowLoginForm(true);
        }}
        sx={{
          width: "200px",
          display: "flex",
          height: "60px",
          mr: "20px",
          ml: "auto",
          mt: 4,
          px: 3,
          border: "3px solid black",
          backgroundColor: "white",
          filter: "drop-shadow(0px 0px 10px white)",
          "&:hover": {
            backgroundColor: "#f7ba4c",
          },
        }}
      >
        <Typography sx={{ fontWeight: "bold", color: "black" }}>
          LOGIN
        </Typography>
      </Button>
    </Box>
  );
};
