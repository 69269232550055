import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { fs, invoke } from "@tauri-apps/api";
import { BaseDirectory } from "@tauri-apps/api/path";
import { Buffer } from "buffer";
import * as x509 from "@peculiar/x509";
import posthog from "posthog-js";
import {
  LogoutDocument,
  StationLoginDocument,
  PlayFromHomeLoginDocument,
  GetPlayerRoomInfoDocument,
  RefreshTokenDocument,
  RefreshStationCertificateDocument,
} from "../generated/graphql";
import { isJwtStale } from "../GqlProvider";
import { createClient, Exchange, fetchExchange } from "urql";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export enum GameSource {
  EQUINOX = "EQUINOX",
  GMW = "GMW",
  INTERNAL = "INTERNAL",
}

interface RoomInfo {
  roomId: string;
  roomName: string;
  roomLocation: string;
}
interface loginInput {
  playerId?: string;
  cardSerialNumber?: string;
  email?: string;
  password?: string;
}

interface logoutInput {
  withCashout: boolean;
}

interface AuthContextType {
  playerId: string | undefined;
  username: string | undefined;
  playerSessionId: string | undefined;
  showLoginForm: boolean;
  isTauriApp: boolean;
  isMuted: boolean;
  jwt: string | undefined;
  cert: string | undefined;
  signature: string | undefined;
  maintenanceMode: boolean;
  gameSource: GameSource | undefined;
  roomInfo: RoomInfo | undefined;
  // View
  showPetSidebar: boolean;

  // Functions
  login: (loginInput: loginInput) => Promise<boolean>;
  logout: (logoutInput: logoutInput) => void;
  refreshToken: () => Promise<void>;
  setShowLoginForm: (showLoginForm: boolean) => void;
  setIsMuted: (isMuted: boolean) => void;
  setMaintenanceMode: (maintenanceMode: boolean) => void;
  hardReset: () => void;
  setGameSource: (gameSource: GameSource) => void;
  setShowPetSidebar: (showPetSidebar: boolean) => void;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

export const StationAuthProvider = ({ children }: AuthProviderProps) => {
  const [playerId, _setPlayerId] = useState<string | undefined>(undefined);
  const [username, _setUsername] = useState<string | undefined>(undefined);
  const [playerSessionId, _setPlayerSessionId] = useState<string | undefined>(
    undefined
  );
  const [jwt, _setJwt] = useState<string | undefined>(undefined);
  const [cert, _setCert] = useState<string | undefined>(undefined);
  const [signature, _setSignature] = useState<string | undefined>(undefined);
  const [showLoginForm, _setShowLoginForm] = useState(false);
  const [isTauriApp, _setIsTauriApp] = useState(false);
  const [isMuted, _setIsMuted] = useState(true);
  const [maintenanceMode, _setMaintenanceMode] = useState(false);
  const [gameSource, _setGameSource] = useState<GameSource | undefined>(
    undefined
  );
  const [roomInfo, _setRoomInfo] = useState<RoomInfo | undefined>(undefined);
  const [showPetSidebar, _setShowPetSidebar] = useState(false);
  const navigate = useNavigate();

  // Sets if tauri app or web
  useEffect(() => {
    const initialize = async () => {
      if (window.__TAURI__) _setIsTauriApp(true);
      else _setIsTauriApp(false);
    };
    initialize();
  }, []);

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    const cert = localStorage.getItem("cert");
    const signature = localStorage.getItem("signature");

    if (jwt) _setJwt(jwt);
    if (cert) _setCert(cert);
    if (signature) _setSignature(signature);
  }, [jwt, cert, signature]);

  // Checks for existing web app login session & sets isTauriApp
  useEffect(() => {
    const initialize = async () => {
      const _jwt = localStorage.getItem("jwt");
      const _playerSessionId = localStorage.getItem("playerSessionId");
      if (_jwt && _playerSessionId && !window.__TAURI__) {
        const isStale = isJwtStale(_jwt!);
        if (!isStale) {
          const decodedJwt = decodeJWT(_jwt!);
          const playerId = decodedJwt.payload.playerId;
          const playerUsername = decodedJwt.payload.username;
          _setPlayerId(playerId);
          _setUsername(playerUsername);
          _setPlayerSessionId(_playerSessionId);
        } else {
          await refreshToken();
        }
      }
    };
    initialize();
  }, []);

  // Gets Room Information When PlayerId Changes
  useEffect(() => {
    if (playerId) {
      const getPlayerRoomInfo = async () => {
        const response = await createClient({
          url: `${import.meta.env.VITE_APP_API!}/station/graphql`,
          exchanges: [fetchExchange],
        }).executeQuery({
          query: GetPlayerRoomInfoDocument,
          variables: {
            playerId: playerId,
          },
          key: 0,
        });
        if (response?.data?.getPlayerRoomInfo) {
          const roomInfo = response.data.getPlayerRoomInfo;
          _setRoomInfo(roomInfo);
        }
      };
      getPlayerRoomInfo();
    }
  }, [playerId]);

  const login = async (loginInput: loginInput): Promise<boolean> => {
    const { playerId, cardSerialNumber, email, password } = loginInput;

    if (window.__TAURI__) {
      try {
        const shouldRefreshCert = await invoke("should_refresh_certificate");
        if (shouldRefreshCert) {
          console.log("🚨 Station certificate needs to be refreshed");
          await refreshCertificate();
        } else {
          console.log("✅ Station certificate do not needs to be refreshed");
        }

        const certfile = await fs.readBinaryFile("station-cert.pem", {
          dir: BaseDirectory.AppData,
        });
        const cert = Buffer.from(certfile).toString("base64");
        _setCert(cert);
        localStorage.setItem("cert", cert);
        const { subject } = new x509.X509Certificate(certfile);
        const parsedSubject = parseCertificateSubject(subject);
        const stationId = parsedSubject["CN"];
        const message = `${stationId}`;
        const base64EncodedMessage = Buffer.from(message).toString("base64");
        const signature = await invoke("get_signature", {
          base64Message: base64EncodedMessage,
        });
        _setSignature(signature as string);
        localStorage.setItem("signature", signature as string);
        if (!playerId || !cardSerialNumber) return false;

        const response = await createClient({
          url: `${import.meta.env.VITE_APP_API!}/station/graphql`,
          fetchOptions: () => ({
            headers: {
              "x-client-cert": cert || "",
              "x-client-signature": signature as string,
            },
          }),
          exchanges: [fetchExchange],
        }).executeMutation({
          query: StationLoginDocument,
          variables: {
            playerId: playerId,
            cardSerialNumber: cardSerialNumber,
          },
          key: 0,
        });

        if (response?.data?.stationLogin) {
          const loginResponse = response.data.stationLogin;
          if (loginResponse?.username) _setUsername(loginResponse?.username);
          if (loginResponse?.playerId) _setPlayerId(loginResponse?.playerId);
          if (loginResponse?.playerSessionId)
            _setPlayerSessionId(loginResponse.playerSessionId);
          if (loginResponse?.token) {
            localStorage.setItem("jwt", loginResponse.token);
            _setJwt(loginResponse.token);
          }
          if (loginResponse?.refreshToken)
            localStorage.setItem("refreshToken", loginResponse.refreshToken);

          if (
            !import.meta.env.VITE_APP_API.includes("localhost") &&
            !import.meta.env.VITE_APP_API.includes("staging") &&
            posthog
          ) {
            posthog.identify(
              loginResponse.playerId, // Unique ID for the user
              {
                email: loginResponse.playerEmail,
                id: loginResponse.playerId,
                username: loginResponse.username,
                playerSessionId: loginResponse.playerSessionId,
              }
            );
          }
          return true;
        }
        return false;
      } catch {
        return false;
      }
    } else {
      if (!email || !password) return false;
      try {
        const response = await createClient({
          url: `${import.meta.env.VITE_APP_API!}/station/graphql`,
          exchanges: [fetchExchange],
        }).executeMutation({
          query: PlayFromHomeLoginDocument,
          variables: {
            email: email,
            password: password,
          },
          key: 0,
        });

        if (response?.data?.playFromHomeLogin) {
          const loginResponse = response.data.playFromHomeLogin;
          if (loginResponse?.username) _setUsername(loginResponse.username);
          _setPlayerId(loginResponse.playerId);
          _setPlayerSessionId(loginResponse.playerSessionId);
          localStorage.setItem("jwt", loginResponse.token);
          _setJwt(loginResponse.token);
          localStorage.setItem("refreshToken", loginResponse.refreshToken);
          localStorage.setItem(
            "playerSessionId",
            loginResponse.playerSessionId
          );

          await IdentifyPostHogSession(loginResponse);

          return true;
        }
      } catch {
        return false;
      }
    }
    return false;
  };

  const refreshToken = async () => {
    try {
      const refreshToken = await localStorage.getItem("refreshToken");
      const res = await createClient({
        url: `${import.meta.env.VITE_APP_API!}/station/graphql`,
        fetchOptions: () => ({
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }),
        exchanges: [fetchExchange],
      }).executeMutation({
        query: RefreshTokenDocument,
        variables: {
          refreshToken: refreshToken,
        },
        key: 0,
      });
      console.log("🚀 Refreshed Auth Session");
      const response = res.data?.refreshToken;
      localStorage.setItem("jwt", response.token);
      localStorage.setItem("refreshToken", response.refreshToken);
      const decodedJwt = decodeJWT(response.token);
      const playerId = decodedJwt.payload.playerId;
      const playerUsername = decodedJwt.payload.username;
      const _playerSessionId = decodedJwt.payload.playerSessionId;
      _setPlayerId(playerId);
      _setUsername(playerUsername);
      _setPlayerSessionId(_playerSessionId);
      await IdentifyPostHogSession(decodedJwt.payload);
    } catch {
      hardReset();
    }
  };

  const logout = async ({ withCashout }: logoutInput) => {
    if (playerSessionId && !withCashout) {
      const jwt = localStorage.getItem("jwt");
      const cert = localStorage.getItem("cert");
      const signature = localStorage.getItem("signature");
      // Reset state
      _setPlayerId(undefined);
      _setUsername(undefined);
      _setPlayerSessionId(undefined);
      _setJwt(undefined);
      _setCert(undefined);
      // Reset local storage
      localStorage.removeItem("jwt");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("playerSessionId");
      localStorage.removeItem("cert");
      localStorage.removeItem("signature");
      localStorage.removeItem("playerId");
      // Clears super properties and generates a new random distinct_id for this instance.
      // Useful for clearing data when a user logs out
      posthog.reset();

      await createClient({
        url: `${import.meta.env.VITE_APP_API!}/station/graphql`,
        fetchOptions: () => ({
          headers: {
            Authorization: jwt ? `Bearer ${jwt}` : "",
            "X-Certificate": cert || "",
            "X-Signature": signature || "",
          },
        }),
        exchanges: [fetchExchange],
      }).executeMutation({
        query: LogoutDocument,
        variables: {
          sessionKey: playerSessionId,
        },
        key: 0,
      });
    } else {
      // If without cashout, just reset state and local storage
      // Reset state
      _setPlayerId(undefined);
      _setUsername(undefined);
      _setPlayerSessionId(undefined);
      _setJwt(undefined);
      _setCert(undefined);
      // Reset local storage
      localStorage.removeItem("jwt");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("playerSessionId");
      localStorage.removeItem("cert");
      localStorage.removeItem("signature");
      localStorage.removeItem("playerId");
    }
  };

  const hardReset = () => {
    // Reset state
    _setPlayerId(undefined);
    _setUsername(undefined);
    _setPlayerSessionId(undefined);
    _setJwt(undefined);
    _setCert(undefined);
    // Clears super properties and generates a new random distinct_id for this instance.
    // Useful for clearing data when a user logs out
    posthog.reset();

    // Reset local storage
    localStorage.removeItem("jwt");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("playerSessionId");
    localStorage.removeItem("cert");
    localStorage.removeItem("signature");
    localStorage.removeItem("playerId");
    // Redirect to main page
    navigate("/");
  };

  const setIsMuted = (_isMuted: boolean) => _setIsMuted(_isMuted);
  const setMaintenanceMode = (_maintenanceMode: boolean) =>
    _setMaintenanceMode(_maintenanceMode);

  const setShowLoginForm = (_showLogInForm: boolean) =>
    _setShowLoginForm(_showLogInForm);

  const setGameSource = (_gameSource: GameSource) =>
    _setGameSource(_gameSource);

  const refreshCertificate = async () => {
    if (isTauriApp) {
      const oldCertificate = await fs.readBinaryFile("station-cert.pem", {
        dir: BaseDirectory.AppData,
      });

      await invoke("refresh_certificate");

      const newCert = await fs.readBinaryFile("station-cert.pem", {
        dir: BaseDirectory.AppData,
      });

      // make a gql to backend to update cert fingerprint
      const res = await createClient({
        url: `${import.meta.env.VITE_APP_API!}/station/graphql`,
        fetchOptions: () => ({
          headers: {
            "x-client-cert":
              Buffer.from(oldCertificate).toString("base64") || "",
            "x-client-signature": signature as string,
          },
        }),
        exchanges: [fetchExchange],
      }).executeMutation({
        query: RefreshStationCertificateDocument,
        variables: {
          newCert: Buffer.from(newCert).toString("base64"),
        },
        key: 0,
      });
      _setCert(Buffer.from(newCert).toString("base64"));
      localStorage.setItem("cert", Buffer.from(newCert).toString("base64"));
    }
  };

  const setShowPetSidebar = (_showPetSidebar: boolean) =>
    _setShowPetSidebar(_showPetSidebar);

  const IdentifyPostHogSession = async (loginResponse: any) => {
    if (
      !import.meta.env.VITE_APP_API.includes("localhost") &&
      !import.meta.env.VITE_APP_API.includes("staging") &&
      posthog
    ) {
      posthog.identify(
        loginResponse.playerId, // Unique ID for the user
        {
          email: loginResponse.playerEmail,
          id: loginResponse.playerId,
          username: loginResponse.username,
          playerSessionId: loginResponse.playerSessionId,
        }
      );
    }
  };

  return (
    <AuthContext.Provider
      value={{
        showLoginForm,
        isTauriApp,
        isMuted,
        playerId,
        username,
        playerSessionId,
        cert,
        signature,
        jwt,
        maintenanceMode,
        gameSource,
        roomInfo,
        showPetSidebar,
        // Functions
        login,
        logout,
        refreshToken,
        setShowLoginForm,
        setIsMuted,
        setMaintenanceMode,
        hardReset,
        setGameSource,
        setShowPetSidebar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useStationAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useStationAuth must be used within an StationAuthProvider"
    );
  }
  return context;
};
// Helper Functions
function base64urlDecode(str: string) {
  return decodeURIComponent(
    atob(str.replace(/_/g, "/").replace(/-/g, "+"))
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}
function decodeJWT(token: string) {
  const parts = token.split(".");
  if (parts.length !== 3) throw new Error("Invalid JWT token");
  const header = JSON.parse(base64urlDecode(parts[0]));
  const payload = JSON.parse(base64urlDecode(parts[1]));

  return {
    header: header,
    payload: payload,
    signature: parts[2],
  };
}
const parseCertificateSubject = (subject: string) => {
  const keyValuePairs = subject
    .split(",")
    .map((pair) => pair.trim().split("="));
  const subjectObject = keyValuePairs.reduce(
    (acc: { [key: string]: string }, [key, value]: any) => {
      acc[key] = value;
      return acc;
    },
    {}
  );
  return subjectObject;
};
