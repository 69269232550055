import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// Styles
import "./styles.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/pagination";

import { BrowserRouter } from "react-router-dom";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { StationAuthProvider } from "./contexts/StationAuthContext";
import "./utils/newRelic";
import { GqlProvider } from "./GqlProvider";
import { BalanceProvider } from "./contexts/BalanceContext";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <StationAuthProvider>
      <GqlProvider>
        <BalanceProvider>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <App />
            </CollapseDrawerProvider>
          </SettingsProvider>
        </BalanceProvider>
      </GqlProvider>
    </StationAuthProvider>
  </BrowserRouter>
);
