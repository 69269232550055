import { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useStationAuth } from "../../contexts/StationAuthContext";
import { useGetBalance } from "../../contexts/BalanceContext";
import pets from "./pets.json";
import Incubator from "./Incubator";
import HowItWorks from "./HowItWorks";
import Iconify from "../Iconify";

import {
  useGetUserPetsQuery,
  useCanClaimPetQuery,
  useClaimPetMutation,
} from "../../generated/graphql";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

export default function PetView({
  tutorialModeStep,
  setTutorialModeStep,
}: {
  tutorialModeStep: number;
  setTutorialModeStep: (tutorialModeStep: number) => void;
}) {
  const { playerId } = useStationAuth();
  const { balance } = useGetBalance();
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  const [{ data: userPetsData }] = useGetUserPetsQuery({
    variables: { playerId: playerId || "" },
  });
  const userPets = userPetsData?.getUserPets;

  const [{ data: canClaimPetData }] = useCanClaimPetQuery({
    variables: { playerId: playerId || "" },
  });
  const canClaimPet = canClaimPetData?.canClaimPet;

  const showClaimPet =
    canClaimPet && userPets?.length === 0 && window.innerWidth > 1250;
  const isInGame = useLocation().pathname.includes("/game");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* Top Bar - Candy */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            setShowHowItWorks(true);
          }}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            justifyItems: "center",
            background: "#4272E1",
            opacity: isInGame ? 0.5 : 1,
            borderRadius: "20px",
            padding: "15px",
            height: "50px",
            minWidth: "175px",
            position: "relative",
            mt: 2,
            mb: 1,
            ml: 5,
          }}
        >
          <img
            className="float"
            src="/images/sidebar/candy.png"
            alt="Candy"
            width="100px"
            style={{
              position: "absolute",
              top: -25,
              left: -20,
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "bold",
                color: "white",
                mx: 1,
                textAlign: "center",
              }}
            >
              {balance?.candy ? parseInt(balance?.candy).toLocaleString() : "0"}
            </Typography>
          </Box>
        </Button>
        {/* Info */}
        <IconButton
          onClick={() => {
            setShowHowItWorks(true);
          }}
          sx={{
            mt: 1,
            ml: 0.5,
          }}
        >
          <Iconify
            icon="mdi:information-outline"
            width={27}
            height={27}
            sx={{
              color: "white",
              opacity: isInGame ? 0.5 : 1,
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {showClaimPet ? (
          <ClaimPet
            tutorialModeStep={tutorialModeStep}
            setTutorialModeStep={setTutorialModeStep}
          />
        ) : userPetsData?.getUserPets &&
          userPetsData?.getUserPets.length !== 0 ? (
          <Incubator
            tutorialModeStep={tutorialModeStep}
            setTutorialModeStep={setTutorialModeStep}
          />
        ) : null}
      </Box>

      <HowItWorks
        showHowItWorks={showHowItWorks}
        setShowHowItWorks={setShowHowItWorks}
      />
    </Box>
  );
}

const ClaimPet = ({
  tutorialModeStep,
  setTutorialModeStep,
}: {
  tutorialModeStep: number;
  setTutorialModeStep: (tutorialModeStep: number) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [petSelected, setPetSelected] = useState(false);
  const [selectedPet, setSelectedPet] = useState<any>(null);
  const [eggOpen, setEggOpen] = useState(false);
  const [displayIntro, setDisplayIntro] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const { playerId } = useStationAuth();
  const getRandomPetByType = (type: string) => {
    const petsOfType = pets.filter((pet) => pet.type === type);
    return petsOfType[Math.floor(Math.random() * petsOfType.length)];
  };

  // Add useEffect to handle the intro timing
  useEffect(() => {
    if (displayIntro) {
      // Start fade out after 2.7 seconds
      setTimeout(() => setFadeOut(true), 2700);
      // Switch to pet selection after fade completes (3 seconds total)
      setTimeout(() => setDisplayIntro(false), 3000);
    }
  }, []);

  // Get one random pet of each type
  const selectedPets = [
    getRandomPetByType("DOG"),
    getRandomPetByType("CAT"),
    getRandomPetByType("PIGEON"),
  ];

  const [{ data: claimPetData }, claimPet] = useClaimPetMutation();

  const [{ data: userPetsData }, getUserPets] = useGetUserPetsQuery({
    variables: { playerId: playerId || "" },
  });

  const showClaimPet =
    userPetsData?.getUserPets.length === 0 && window.innerWidth > 1250;

  const onClickClaimPet = async (pet: any) => {
    if (loading) return;
    try {
      if (window.innerWidth > 1250) setTutorialModeStep(2);
      setLoading(true);

      if (!playerId) throw new Error("Player ID is required");

      const audio = new Audio(`/sounds/pet-${pet.type.toLowerCase()}.wav`);
      await audio.play();

      // Remove the return statement and proceed with claiming the pet
      await claimPet({ playerId: playerId, petId: pet.id.toString() });
      setPetSelected(true);
      await getUserPets({
        variables: { playerId: playerId },
      });
    } catch (error) {
      console.error("Error claiming pet:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {showClaimPet && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1201,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0,
            animation: "fadeIn 0.3s ease-in forwards",
            "@keyframes fadeIn": {
              from: { opacity: 0 },
              to: { opacity: 1 },
            },
          }}
        >
          {petSelected ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mr: 35,
                opacity: fadeOut ? 0 : 1,
                transition: "opacity 0.3s ease-out",
              }}
            />
          ) : displayIntro ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mr: 35,
                opacity: fadeOut ? 0 : 1,
                transition: "opacity 0.3s ease-out",
              }}
            >
              <img src="/logo.png" alt="Alcade Pet Intro" width="500px" />
            </Box>
          ) : selectedPet !== null ? (
            <Box
              sx={{
                marginRight: "235px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  animation: "fadeIn 0.5s ease-in",
                  opacity: 1,
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #000000",
                    borderRadius: "10px",
                    padding: "20px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "24px",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Tap the egg to hatch your pet!
                  </Typography>
                </Box>
                <Box className="float">
                  <Iconify
                    icon="material-symbols:line-end-arrow-notch-rounded"
                    width={150}
                    height={150}
                    style={{
                      color: "#FFFFFF",
                      transform: "rotate(90deg)",
                    }}
                  />
                </Box>
              </Box>
              <Button
                disabled={loading || eggOpen}
                onClick={() => {
                  if (loading || eggOpen) return;
                  setEggOpen(true);
                  setTimeout(() => {
                    onClickClaimPet(selectedPet);
                  }, 2500);
                }}
                sx={{
                  cursor: "pointer",
                  "@keyframes shake": {
                    "0%, 100%": { transform: "translateX(0)" },
                    "25%": { transform: "translateX(-10px)" },
                    "75%": { transform: "translateX(10px)" },
                  },
                }}
              >
                <Box
                  sx={{
                    "@keyframes pulse": {
                      "0%": { transform: "scale(1)" },
                      "50%": { transform: "scale(1.1)" },
                      "100%": { transform: "scale(1)" },
                    },
                    "@keyframes glow": {
                      "0%": {
                        filter:
                          "contrast(200%) brightness(150%) drop-shadow(0 0 5px #fff)",
                      },
                      "50%": {
                        filter:
                          "contrast(200%) brightness(200%) drop-shadow(0 0 20px #fff)",
                      },
                      "100%": {
                        filter:
                          "contrast(200%) brightness(150%) drop-shadow(0 0 5px #fff)",
                      },
                    },
                  }}
                >
                  <img
                    src={"./images/egg.gif"}
                    alt={"egg"}
                    height={"250px"}
                    width={"250px"}
                    className="float-pet"
                    style={{
                      maxWidth: "auto",
                      filter: eggOpen
                        ? "contrast(200%) brightness(150%)"
                        : "none",
                      animation: eggOpen
                        ? "pulse 0.5s ease-in-out infinite, glow 0.5s ease-in-out infinite"
                        : "none",
                    }}
                  />
                </Box>
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mr: 35,
                opacity: displayIntro ? 0 : 1,
                transition: "opacity 0.3s ease-in",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  mb: 2,
                  fontStyle: "italic",
                  fontWeight: "bold",
                  "@media (max-width: 1400px)": {
                    fontSize: "3rem",
                  },
                  "@media (max-width: 1200px)": {
                    fontSize: "2.5rem",
                  },
                  "@media (max-width: 900px)": {
                    fontSize: "2rem",
                  },
                }}
              >
                Choose your pet!
              </Typography>
              <Box
                sx={{
                  borderRadius: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedPets.map((pet) => (
                  <Button
                    key={pet?.id}
                    disabled={loading}
                    onClick={() => {
                      setSelectedPet(pet);
                    }}
                    sx={{
                      m: 3,
                      height: "300px",
                      width: "300px",
                      borderRadius: "50%",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                      background:
                        "linear-gradient(225deg, rgba(150,187,255,1) 34%, rgba(87,127,167,1) 74%)",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.1)",
                        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                      },
                      "@media (max-width: 1400px)": {
                        height: "250px",
                        width: "250px",
                      },
                      "@media (max-width: 1200px)": {
                        height: "200px",
                        width: "200px",
                      },
                      "@media (max-width: 900px)": {
                        height: "100px",
                        width: "100px",
                      },
                    }}
                  >
                    <img
                      src={pet?.img + "_1.png"}
                      alt={pet?.name}
                      height="250px"
                      width="250px"
                      className="float-pet"
                    />
                  </Button>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
